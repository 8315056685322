import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";

const Index = () => {
  const [ipinfo, setipinfo] = useState({
    ip: null,
    city: null,
    country: null,
    timezone: null,
  });
  const [err, seterr] = useState(false);
  const [isloading, setloading] = useState(true);
  useEffect(() => {
    const ipinfo = "https://ipinfo.io?token=d23bef287b5c00"; //50k/month
    // const freeip = "https://freeipapi.com/api/json";   // 60/min
    // const ipgeolocation="https://api.ipgeolocation.io/ipgeo?apiKey=4a860b35a5c74737872b42a1a35641dd"   // 1k/day(total 30k)
    axios
      .get(ipinfo)
      .then((res) => {
        // console.log(`res`, res);
        if (res.status === 200) {
          // console.log(`res`, res);
          setipinfo(res.data);
          setloading(false);
        } else {
          seterr(true);
          setloading(false);
        }
      })
      .catch((err) => {
        seterr(true);
        setloading(false);
      });
  }, []);

  return (
    <>
      <div className="mt-5"></div>
      <div
        className="fixed-bottom bg-secondary-subtle d-flex justify-content-center align-items-center"
        style={{ height: "50px" }}
      >
        {err ? (
          <div className="text-center text-danger">something wrong</div>
        ) : isloading ? (
          <div className="text-info">Loading</div>
        ) : (
          <div className="d-flex justify-content-center text-nowrap">
            <span>{ipinfo.ip} </span>
            <span className="mx-2">
              {ipinfo.city} {ipinfo.country}
            </span>
            <span className="ms-2"> {ipinfo.timezone}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Index;
