import React from "react";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/currency">currency</Link>
          </li>
          <li>
            <Link to="/holiday">holiday</Link>
          </li>
        </ul>
      </nav> */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src="./Images/Qlogo_removebg.png"
              alt="logo"
              width={52}
              height={23}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/currency">
                  Currency
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/holiday">
                  Holiday
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/myip">
                  MyIP
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
