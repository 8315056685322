import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  currencyBase,
  currencyTarget,
  currancyAmount,
  //currencyConvert,
  loadAsync,
} from "./sliceCurrency";

const CurrencyExchange = () => {
  const currencyData = useSelector((state) => state.currency.currencyData);
  const dispatch = useDispatch();
  const base = useSelector((state) => state.currency.base);
  const target = useSelector((state) => state.currency.target);
  const amount = useSelector((state) => state.currency.amount);
  const isloading = useSelector((state) => state.currency.isloading);
  const update = useSelector((state) => state.currency.update);
  const result = useSelector((state) => state.currency.result);
  const iserror = useSelector((state) => state.currency.iserror); //判断输入是否错误
  return (
    <>
      <p className="text-center text-primary mt-2">Currency Exchange Tool</p>

      <div className="row">
        {isloading ? (
          <div className="text-center form-control-lg text-info fs-3">
            {result} Loading
          </div>
        ) : (
          <div className="text-center form-control-lg text-danger fs-2">
            {result}{" "}
            <span className="fs-6 fw-light text-muted">
              {update != null
                ? moment(update).format("HH:mm ddd DD MMM YYYY")
                : ""}
            </span>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col">
          <label htmlFor="baseCurrency" className="form-label">
            Base Currency
          </label>
          <select
            id="baseCurrency"
            className="form-select"
            defaultValue={base}
            onChange={(e) => dispatch(currencyBase(e.target.value))}
          >
            {currencyData.map((item, index) => {
              return (
                <option key={index} value={item.currencyCode}>
                  {item.currencyName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col">
          <label htmlFor="targetCurrency" className="form-label">
            Target Currency
          </label>
          <select
            id="targetCurrency"
            className="form-select"
            defaultValue={target}
            onChange={(e) => dispatch(currencyTarget(e.target.value))}
          >
            {currencyData.map((item, index) => {
              return (
                <option key={index} value={item.currencyCode}>
                  {item.currencyName}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <input
            type="digit"
            min={0}
            className="form-control form-control-lg"
            value={amount}
            placeholder="entry amount"
            onChange={(e) => dispatch(currancyAmount(e.target.value))}
          />
          {iserror ? (
            <div className="text-danger fs-6 mt-1 w-100">
              must be digit and great than 0.
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col">
          <button
            className="btn btn-outline-primary btn-lg w-100"
            onClick={() => dispatch(loadAsync({ base, target, amount }))}
          >
            Convert
          </button>
        </div>
      </div>
    </>
  );
};

export default CurrencyExchange;
