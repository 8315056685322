import React from "react";
import Ipinfo from "./Ipinfo";

import { Helmet } from "react-helmet";

//my ip components
const Index = () => {
  return (
    <>
      <Helmet>
        <title>my ip address | fang.co.nz</title>
        <meta charSet="uft-8" />
        <meta name="keywords" content="my ip address" />
        <meta name="description" content="my ip address - fang.co.nz" />
        <meta name="Robots" contect="all|index|follow" />
      </Helmet>
      <Ipinfo />
    </>
  );
};

export default Index;
