import Axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import moment from "moment/moment";

//import f from "../../../public/Images/meteosource_weather_icons";

const MeteoSource = () => {
  const [err, seterr] = useState(false);
  const [isloading, setloading] = useState(true);
  const [lat, setlat] = useState(-36.968); //-36.929536
  const [lon, setlon] = useState(74.929962); //174.6829312
  const [weatherData, setweahterData] = useState();

  //每天400个请求
  const url_geo = `https://www.meteosource.com/api/v1/free/point?lat=${lat}&lon=${lon}&sections=all&language=en&units=auto&key=9puouiqxxltvqxf3mcbz9h6zedfkz6jofkpllbx5`;

  const url_placeID =
    "https://www.meteosource.com/api/v1/free/point?place_id=auckland&sections=all&language=en&units=auto&key=9puouiqxxltvqxf3mcbz9h6zedfkz6jofkpllbx5";

  //const url = `https://www.meteosource.com/api/v1/free/point?lat=${lat}&lon=${lon}&sections=current%2Cdaily&language=en&units=auto&key=9puouiqxxltvqxf3mcbz9h6zedfkz6jofkpllbx5`;

  // navigator.geolocation.getCurrentPosition((postition) => {
  //   setlat(postition.coords.latitude);
  //   setlon(postition.coords.longitude);
  //   // console.log(postition.coords.latitude);
  //   // console.log(postition.coords.longitude);
  // });

  // getWeather(url_placeID);

  function changeLocation() {
    navigator.geolocation.getCurrentPosition((postition) => {
      setlat(postition.coords.latitude);
      setlon(postition.coords.longitude);
      // console.log(postition.coords.latitude);
      // console.log(postition.coords.longitude);
      getWeather(url_geo);
    });
  }

  function getWeather(url) {
    //console.log("url", url);
    Axios.get(url)
      .then((res) => {
        //console.log(`res`, res);
        setweahterData(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        seterr(true);
      });
  }

  useEffect(() => {
    getWeather(url_placeID);
  }, []);

  return (
    <>
      <div className="card my-2">
        {err ? (
          <div className="text-danger text-center">something wrong</div>
        ) : isloading ? (
          <div className="text-info text-center">loading</div>
        ) : (
          <div className="card-body">
            <div className="d-flex justify-content-around my-2 border-bottom pb-3">
              <span>
                <img
                  src={`../../../Images/meteosource_weather_icons/set01/small/${weatherData.current.icon_num}.png`}
                  alt="log"
                  width={25}
                  height={25}
                />
              </span>
              <span>{Math.round(weatherData.current.temperature)} ℃</span>
              <span>
                {weatherData.timezone}
                <i className="ms-2 click text-primary" onClick={changeLocation}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-crosshair"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7.001 7.001 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7.001 7.001 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7.001 7.001 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7.001 7.001 0 0 0 8.5 1.018zm-6.48 7A6.001 6.001 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6.001 6.001 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6.002 6.002 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6.001 6.001 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1h-.48M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                  </svg>
                </i>
              </span>
            </div>
            <table className="table table-hover">
              <tbody>
                {weatherData.daily.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(item.day).format("ddd DD")}</td>
                      <td>
                        <img
                          src={`../../../Images/meteosource_weather_icons/set01/small/${item.icon}.png`}
                          alt="log"
                          width={25}
                          height={25}
                        />
                      </td>
                      <td>{item.weather}</td>
                      <td>
                        {Math.round(item.all_day.temperature_min)}° -{" "}
                        {Math.round(item.all_day.temperature_max)}°
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default MeteoSource;
