import{configureStore} from '@reduxjs/toolkit'
import sliceCurrency from '../features/currency/sliceCurrency'
import sliceHoliday from '../features/holiday/sliceHoliday'



export default configureStore({
    reducer:{
        currency:sliceCurrency,
        holiday:sliceHoliday
    },
   
})