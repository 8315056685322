//import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/js/bootstrap'

import Currency from './features/currency/Index'
import Holiday from './features/holiday/Index'
import Myip from './features/myip/Index'
import Home from './features/home/Index'
import MyLayout from './features/myLayout/Index'
import Nofound from './features/nofound/Index'
//import MyHeader from './features/myHeader/Index'
import { BrowserRouter,Route,Routes } from 'react-router-dom';

import { SpeedInsights } from '@vercel/speed-insights/react';
import { inject } from '@vercel/analytics';

function App() {
  return (<>
    <div className="container">
   
    <BrowserRouter >
       <Routes> 
           <Route path='/' element={<MyLayout />} >
              <Route  path="/" element={<Home/>} onEnter={()=>{document.title="my home fang.co.nz"}} >
                </Route>
                <Route  path="/holiday" element={<Holiday/>} >
                </Route>
                <Route  path="/Currency" element={<Currency/>} >
                </Route>
                <Route  path="/myip" element={<Myip/>} >
                </Route>
                <Route  path="*" element={<Nofound/>} >
                </Route>
           </Route>
        </Routes>
    </BrowserRouter>
     <SpeedInsights />
   
   { //vercel网页统计
   inject()
   }
    </div>
  </>);
}

export default App;
