import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//import Hds from "date-holidays";
import { loadAsync } from "./sliceHoliday";

const HolidayTable = () => {
  //const hd = new Hds("NZ", "AUK");
  //const h23 = hd.getHolidays(2023);
  //const [holiday, setholiday] = useState(h23);

  // console.log(`holiday`, holiday);

  //判断即将到来的下一个公共假期
  // const newholiday = holiday.filter((item) => {
  //   return moment().isBefore(moment(item.date));
  // });

  // function changeYear(e) {
  //   setholiday(hd.getHolidays(e.target.innerText));
  // }

  const comingHoliday = useSelector((state) => state.holiday.comingHoliday);
  const currentHoliday = useSelector((state) => state.holiday.currentHoliday);
  const isloading = useSelector((state) => state.holiday.isloading);
  const dispatch = useDispatch();
  // console.log("currentHoliday", currentHoliday);
  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <button
          onClick={(e) => dispatch(loadAsync(e.target.innerText))}
          className="btn btn-outline-primary"
        >
          2024
        </button>
        <button
          onClick={(e) => dispatch(loadAsync(e.target.innerText))}
          className="btn btn-outline-success"
        >
          2025
        </button>
        <button
          onClick={(e) => dispatch(loadAsync(e.target.innerText))}
          className="btn btn-outline-danger"
        >
          2026
        </button>
        <button
          onClick={(e) => dispatch(loadAsync(e.target.innerText))}
          className="btn btn-outline-warning"
        >
          2027
        </button>
        <button
          onClick={(e) => dispatch(loadAsync(e.target.innerText))}
          className="btn btn-outline-secondary"
        >
          2028
        </button>
      </div>
      <div className="card mb-4 text-nowarp">
        <div className="card-header">
          <h1 className="card-title fs-5">
            <span className="ms-2"></span>List of public holidays observed in
            New Zealand
          </h1>
        </div>
        <div className="card-body">
          <table className="table table-hover text-nowarp">
            <thead>
              <tr>
                <th>Date</th>
                <th>Day</th>
                <th>Holiday</th>
              </tr>
            </thead>
            <tbody>
              {isloading ? (
                <tr className="text-info text-center">
                  <td>isloading</td>
                </tr>
              ) : (
                currentHoliday.map((item, index) => {
                  if (
                    //当前节日时间的判断,假期名字和假期时间都必须相等
                    item.name === comingHoliday.name &&
                    moment(item.date).format("yyyyMMDD") ===
                      moment(comingHoliday.date).format("yyyyMMDD")
                  ) {
                    return (
                      <tr key={index} className="table-secondary">
                        <td>{moment(item.date).format("DD-MMM-yyyy")}</td>
                        <td>{moment(item.date).format("ddd")}</td>
                        <td className="ps-2">{item.name}</td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index}>
                        <td>{moment(item.date).format("DD-MMM-yyyy")}</td>
                        <td>{moment(item.date).format("ddd")}</td>
                        <td className="ps-2">{item.name}</td>
                      </tr>
                    );
                  }
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HolidayTable;
