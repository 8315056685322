import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";

const CurrencyDetail = () => {
  const [currencyDetail, setcurrencyDetail] = useState("");
  const [update, setupdate] = useState();
  const [isloading, setisloading] = useState(true);
  useEffect(() => {
    //fangtrust
    //https://v6.exchangerate-api.com/v6/a62155243c01007ae1ba9e6f/latest/NZD

    axios
      .get(
        "https://v6.exchangerate-api.com/v6/4732c060db610d6348be3113/latest/NZD"
      )
      .then((res) => {
        // console.log("res", res.data.conversion_rates);
        setcurrencyDetail(res.data.conversion_rates);
        setupdate(res.data.time_last_update_utc);
        setisloading(false);
        //console.log("res", res.data.time_last_update_utc);
      })
      .catch((err) => {
        setcurrencyDetail("");
        setisloading(false);
      });
  }, []);

  return (
    <>
      {isloading ? (
        <div className="text-info fs-3 text-center mt-5">loading</div>
      ) : (
        <>
          <div className="row mt-5">
            <div className="col text-center">
              <span className="fs-2">1 NZD foreign exchange rates</span>
              <div className="text-end">
                <span className="fs-6 w3-text-gray">
                  update: {moment(update).format("HH:mm ddd DD MMM YYYY")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <table className="w3-table w3-bordered">
              <thead>
                <tr>
                  <th>currency</th>
                  <th>rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AUD</td>
                  <td>{currencyDetail.AUD}</td>
                </tr>
                <tr>
                  <td>CNY</td>
                  <td>{currencyDetail.CNY}</td>
                </tr>
                <tr>
                  <td>SGD</td>
                  <td>{currencyDetail.SGD}</td>
                </tr>
                <tr>
                  <td>TWD</td>
                  <td>{currencyDetail.TWD}</td>
                </tr>
                <tr>
                  <td>USD</td>
                  <td>{currencyDetail.USD}</td>
                </tr>
                <tr>
                  <td>HKD</td>
                  <td>{currencyDetail.HKD}</td>
                </tr>
                <tr>
                  <td>EUR</td>
                  <td>{currencyDetail.EUR}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default CurrencyDetail;
