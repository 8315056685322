import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const HolidayCard = () => {
  const comingHoliday = useSelector((state) => state.holiday.comingHoliday);
  const isHoliday = useSelector((state) => state.holiday.isHoliday);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="fs-6">Coming holiday information</div>
        </div>
        <div className="card-body py-0 pt-1">
          {/* <div className="d-flex justify-content-between">
            <span>Coming holiday:</span>
            {isHoliday ? (
              <span className="text-danger fw-semibold">
                Today is a public holiday
              </span>
            ) : (
              <span className="text-secondary ">
                Today is not a publc holiday
              </span>
            )}
          </div> */}

          <div className="d-flex justify-content-center">
            {isHoliday ? (
              <span className="text-danger fw-semibold">
                Today is a public holiday
              </span>
            ) : (
              <span className="text-secondary ">
                Today is not a publc holiday
              </span>
            )}
          </div>
          <div className="d-flex justify-content-between fs-4">
            <span className="text-dark">{comingHoliday.name}</span>{" "}
            <span className="text-info">
              {" "}
              {moment(comingHoliday.date).startOf("day").fromNow()}
            </span>
          </div>
          <h6 className="text-secondary fw-light fs-6 mt-0">
            {moment(comingHoliday.date).format("ddd DD MMM YYYY")}
          </h6>
        </div>
      </div>
    </>
  );
};

export default HolidayCard;
