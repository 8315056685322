import Axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import moment from "moment/moment";

//import f from "../../../public/Images/visualcrossing_WeatherIcons/SVG/1SetColor";

const VisualCrossing = () => {
  const [err, seterr] = useState(false);
  const [isloading, setloading] = useState(true);
  const [lat, setlat] = useState(-36.968); //-36.929536
  const [lon, setlon] = useState(174.929962); //174.6829312
  const [weatherData, setweahterData] = useState();

  //https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/38.9697,-77.385?key=YOUR_API_KEY

  //const appkey = "4DQEBERJCTRS395ZVWYT6URWH";  //fangtrust@outlook.com
  const appkey = "W25KXTNJJXLLAPLBU94Q3ZB6M"; //jackie.fang.nz@gmail.com

  const url_geo = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${lat},${lon}?unitGroup=metric&key=${appkey}`;

  const url_place = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/Auckland,NZ?unitGroup=metric&key=${appkey}`;

  navigator.geolocation.getCurrentPosition((postition) => {
    setlat(postition.coords.latitude);
    setlon(postition.coords.longitude);
    // console.log(postition.coords.latitude);
    // console.log(postition.coords.longitude);
  });

  function changeLocation() {
    navigator.geolocation.getCurrentPosition((postition) => {
      setlat(postition.coords.latitude);
      setlon(postition.coords.longitude);
      // console.log(postition.coords.latitude);
      // console.log(postition.coords.longitude);
      getWeather(url_geo);
    });
  }

  function getWeather(url) {
    Axios.get(url)
      .then((res) => {
        //console.log(`res`, res);
        setweahterData(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        seterr(true);
      });
  }

  useEffect(() => {
    getWeather(url_place);
  }, [url_place]);

  return (
    <>
      <div className=" my-3">
        {err ? (
          <div className="text-danger text-center">something wrong</div>
        ) : isloading ? (
          <div className="text-info text-center mt-5">loading</div>
        ) : (
          <div className="">
            <div className="d-flex justify-content-evenly align-items-baseline my-2 border-bottom fw-medium  pb-2">
              <span>
                <img
                  src={`../../../Images/visualcrossing_WeatherIcons/SVG/3SetColor/${weatherData.currentConditions.icon}.svg`}
                  alt="log"
                  width={20}
                  height={20}
                />{" "}
                <span className="ms-1">
                  {weatherData.currentConditions.conditions} <br />
                </span>
              </span>
              <span>{Math.round(weatherData.currentConditions.temp)} ℃</span>
              <span>
                {/* weatherData.timezone */}
                {weatherData.timezone}
                <i className="ms-2 click text-primary" onClick={changeLocation}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-crosshair"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7.001 7.001 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7.001 7.001 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7.001 7.001 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7.001 7.001 0 0 0 8.5 1.018zm-6.48 7A6.001 6.001 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6.001 6.001 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6.002 6.002 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6.001 6.001 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1h-.48M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                  </svg>
                </i>
              </span>
            </div>
            <table className="table table-hover">
              <tbody>
                {weatherData.days.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(item.datetime).format("ddd DD")}</td>
                      <td>
                        <img
                          src={`../../../Images/visualcrossing_WeatherIcons/SVG/3SetColor/${item.icon}.svg`}
                          alt="log"
                          width={30}
                          height={30}
                        />
                      </td>
                      <td>{item.conditions}</td>
                      <td>
                        {Math.round(item.tempmin)}° - {Math.round(item.tempmax)}
                        °
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default VisualCrossing;
