import React from "react";
import { Outlet } from "react-router-dom";
import MyHeader from "../myHeader/Index";
import MyFooter from "../myFooter/Index";

export default function Index() {
  return (
    <>
      <MyHeader />
      <Outlet />
      <MyFooter />
    </>
  );
}
