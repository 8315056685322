import React from "react";
import moment from "moment";
import HolidayTable from "../holiday/HolidayTable";
import CurrencyExchange from "../currency/CurrencyExchange";
//import Metservice from "../widget/Metservice";
//import Niwaweather from "../widget/Niwaweather";
import HolidayCard from "../holiday/HolidayCard";
import MeteoSource from "../widget/MeteoSource";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

//home  components
const Index = () => {
  const comingHoliday = useSelector((state) => state.holiday.comingHoliday);

  return (
    <>
      <Helmet>
        <title>{`nz public holiday|foreign exchange rates| coming holiday - ${
          comingHoliday.name
        }  ${moment(comingHoliday.date).startOf("day").fromNow()}`}</title>
        <meta charSet="uft-8" />
        <meta
          name="keywords"
          content="new zealand,public holiday,currency exchange rates,my ip address"
        />
        <meta
          name="description"
          content="new zealand public holiday, foreign exchange rates and currency exchange tool"
        />
        <meta name="Robots" contect="all|index|follow" />
      </Helmet>
      <div className="row">
        <div className="col-lg-7">
          <HolidayTable />
        </div>
        <div className="col-lg-5">
          <CurrencyExchange />

          <HolidayCard />

          {/* <Niwaweather /> */}

          {/* <Metservice /> */}
          <MeteoSource />
        </div>
      </div>
    </>
  );
};

export default Index;
