import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import moment from "moment";

import HolidayTable from "./HolidayTable";
import VisualCrossing from "../widget/VisualCrossing";
//import MeteoSource from "../widget/MeteoSource";
//import OpenWeatherMap from "../widget/OpenWeatherMap";
//import HolidayCard from "./HolidayCard";

//public holiday components
export default function Index() {
  //从slice 获取即将到来的假期
  const comingHoliday = useSelector((state) => state.holiday.comingHoliday);
  //console.log(`comingHoliday`, comingHoliday.name);

  return (
    <>
      <Helmet>
        <title>{`nz public holiday | coming holiday - ${
          comingHoliday.name
        }  ${moment(comingHoliday.date).startOf("day").fromNow()}`}</title>
        <meta charSet="uft-8"></meta>
        <meta
          name="description"
          content="new zealand publich holiday and current currency rate"
        />
        <meta name="Robots" contect="all|index|follow" />
      </Helmet>
      <div className="row">
        {/* <HolidayCard /> */}
        {/* <OpenWeatherMap /> */}
        {/* <MeteoSource /> */}

        <div className="col-lg-8">
          <HolidayTable />
        </div>
        <div className="col-lg-4">
          <VisualCrossing />
        </div>
      </div>
    </>
  );
}
