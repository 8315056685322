import React from "react";
import CurrencyExchange from "./CurrencyExchange";
import CurrencyDetail from "./CurrencyDetail";
import { Helmet } from "react-helmet";

//currency components
const Index = () => {
  return (
    <>
      <Helmet>
        <title>foreign exchange rates and currency exchange tool</title>
        <meta charSet="uft-8"></meta>
        <meta
          name="description"
          content="foreign exchange rates and currency exchange tool"
        />
        <meta name="Robots" contect="all|index|follow" />
      </Helmet>
      <div className="row">
        <div className="col-lg-6">
          <CurrencyExchange />
        </div>
        <div className="col-lg-6 mb-3">
          <CurrencyDetail />
        </div>
      </div>
    </>
  );
};

export default Index;
