 const currencyData=[
   {currencyCode:'AUD',currencyName:'Australian Dollar'},
    {currencyCode:'CNY',currencyName:'Chinese Renminbi'},
    {currencyCode:'NZD',currencyName:'New Zealand Dollar'},
    {currencyCode:'SGD',currencyName:'Singapore Dollar'},
    {currencyCode:'TWD',currencyName:'New Taiwan Dollar'},
    {currencyCode:'USD',currencyName:'United States Dollar'},
    {currencyCode:'HKD',currencyName:'Hong Kong Dollar'},
    {currencyCode:'EUR',currencyName:'Euro'}
 ]


 



 export default currencyData;