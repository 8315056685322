import React, { useEffect, useState } from "react";
import axios from "axios";

const Index = () => {
  const [ipInfo, setipInfo] = useState({
    ip: null,
    city: null,
    country_name: null,
    isp: null,
  });
  //const [iserr, seterr] = useState(false);
  const [isloading, setloading] = useState(false);

  //dispatch(getCurrentIP(ipInfo));

  useEffect(() => {
    setloading(true);

    const url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=4a860b35a5c74737872b42a1a35641dd"; //1k/day(total 30k)

    axios
      .get(url)
      .then((res) => {
        //console.log(`res`, res.data);

        if (res.status === 200) {
          setipInfo(res.data);
          // seterr(false);

          //dispatch(getCurrentIP(res.data));
          setloading(false);
        } else {
          //seterr(true);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        // seterr(true);
      });
  }, []);
  return (
    <>
      {isloading ? (
        <div className="text-info text-center">loading</div>
      ) : (
        // <p>{ipInfo.ip}</p>
        <div className="list-group mt-1">
          <div className="list-group-item list-group-item-action">
            IP: {ipInfo.ip}
          </div>
          <div className="list-group-item list-group-item-action">
            Location: {ipInfo.city} - {ipInfo.country_name}
          </div>
          <div className="list-group-item list-group-item-action">
            ISP: {ipInfo.isp}
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
