import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import currencyData from "./currencyData";
import axios from "axios";

export const loadAsync=createAsyncThunk('convert',async(state)=>{
   //fangtrust
   //https://v6.exchangerate-api.com/v6/a62155243c01007ae1ba9e6f/pair

    const url = `https://v6.exchangerate-api.com/v6/4732c060db610d6348be3113/pair/${state.base}/${state.target}/${state.amount}`;
   const res=axios.get(url)
  // console.log('url',url);
   return res;
 
})

const sliceCurrency= createSlice({
    name: "currency",
    initialState: {
        currencyData,
        base:'AUD',
        target:'AUD',
        amount:"",  //需要转换的货币
        result:0, //默认结果
        iserror:false, //判断输入是否错误
        isloading:false, //加载
        update:null //数据更新的时间
    },
    reducers: {
        currencyBase:(state,action)=>{
           // console.log('currencyBase state',state.currencyState);
           state.base=action.payload;
            //console.log('currencyBase action',action.payload);
        },
        currencyTarget:(state,action)=>{
           // console.log('currencyTarget action',action.payload);
            state.target=action.payload;
        },
        //输入需要转换的货币值
        currancyAmount:(state,action)=>{
            state.amount =action.payload
            state.iserror=false;

           // console.log('amount ,iserror',state.amount,state.iserror);
            
        },
        currencyConvert: ()=>{
    
        }
    },
    extraReducers:(builder)=>{
      builder
      .addCase(loadAsync.pending,state=>{
        state.isloading=true;
      })
      .addCase(loadAsync.fulfilled,(state,action)=>{
        //console.log('builder',state,action);
        // state.result=res.data.conversion_result;
        // state.update=res.data.time_last_update_utc

       // console.log('builder',action.payload.data.time_last_update_utc);

        state.result=action.payload.data.conversion_result
        state.update=action.payload.data.time_last_update_utc
        state.isloading=false;
      })
      .addCase(loadAsync.rejected,(state)=>{
        state.iserror=true;
        state.isloading=false;
        state.result=0;
        state.update=null;
      })
    }
  
})

export const { currencyBase,currencyTarget,currancyAmount,currencyConvert } = sliceCurrency.actions
export default sliceCurrency.reducer